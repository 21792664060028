import { Link } from 'gatsby';
import React from 'react';
import styled from 'styled-components';
import { bp, gv, ns } from '../../utils';
import { Column, Columns } from '../SiteGrid';

const AuthorImage = styled.div`
	position: absolute;
	top: -36px;
	left: 50%;
	transform: translateX(-50%);
	height: 72px;
	width: 72px;
	padding: 4px;
	background-color: ${gv('appBackgroundColor')};
	border-radius: 100%;
	a {
		display: block;
		text-decoration: none;
		img {
			box-shadow: ${props => props.theme.boxShadow1dp};
			display: block;
			border-radius: 100%;
			display: block;
			height: 64px;
			width: 64px;
		}
	}
`;

const AuthorName = styled.p`
	font-weight: bold;
	color: ${gv('textColor')};
	text-align: center;
	font-family: ${props => props.theme.fontFamilyHeading};
	margin: ${props => ns(props.theme.gutter)} 0
		${props => ns(props.theme.gutter / 2)} 0;
	font-size: ${props => ns(props.theme.fz.large1)};
`;
const AuthorDesignation = styled.p`
	font-weight: normal;
	font-size: ${props => ns(props.theme.fz.base)};
	color: ${gv('textColorSecondary')};
	text-align: center;
	font-family: ${props => props.theme.fontFamilyHeading};
	margin: 0;
`;
const AuthorDesc = styled.p`
	font-size: ${props => ns(props.theme.fz.large1)};
	color: ${gv('textColor')};
	text-align: center;
	margin: ${props => ns(props.theme.gutter)} 0 0 0;
	line-height: 1.5;
`;
const AuthorLinks = styled.div`
	margin: ${props => ns(props.theme.gutter * 2)} 0;
`;
const AuthorLinkHeading = styled.p`
	font-weight: normal;
	color: ${gv('textColorSecondary')};
	font-family: ${props => props.theme.fontFamilyHeading};
	margin: 0 0 ${props => ns(props.theme.gutter / 2)} 0;
	text-align: center;
`;
const AuthorLinkText = styled.a`
	margin: 0;
	font-weight: bold;
	font-family: ${props => props.theme.fontFamilyHeading};
	text-align: center;
	display: block;
	&:hover {
		text-decoration: underline;
	}
`;
const AuthorContainer = styled.div`
	margin: ${props => ns(props.theme.gutter * 2 + 36)} 0
		${props => ns(props.theme.gutter * 2)};
	border-radius: ${props => ns(props.theme.borderRadiusBase * 1.5)};
	background-color: ${gv('backgroundShade')};
	box-shadow: ${props => props.theme.boxShadowBase};
	padding: 36px ${props => ns(props.theme.gutter)}
		${props => ns(props.theme.gutter)};
	position: relative;
	font-size: ${props => ns(props.theme.fz.base)};

	@media ${props => bp(props, 'desktop')} {
		margin-top: ${props => ns(props.theme.gutter * 2 + 72)};
		padding-top: 72px;

		${AuthorImage} {
			top: -72px;
			height: 144px;
			width: 144px;
			padding: 8px;
			a img {
				height: 128px;
				width: 128px;
			}
		}
	}
`;

export type AuthorType = {
	authorId: string;
	name: string;
	designation: string;
	bio: string;
	gravatar: string;
	twitter: string;
	github: string;
	linkedin: string;
};

export default function Author(props: { author: AuthorType }) {
	const { author } = props;

	return (
		<AuthorContainer>
			<AuthorImage>
				<Link to={`/blog/author/${author.authorId}/`} title={author.name}>
					<img
						alt={author.name}
						src={`https://www.gravatar.com/avatar/${author.gravatar}?s=256&r=g`}
						height={256}
						width={256}
					/>
				</Link>
			</AuthorImage>
			<AuthorName>{author.name}</AuthorName>
			<AuthorDesignation>{author.designation}</AuthorDesignation>
			<AuthorDesc>{author.bio}</AuthorDesc>
			<AuthorLinks>
				<Columns>
					<Column className="third">
						<AuthorLinkHeading>Twitter</AuthorLinkHeading>
						<AuthorLinkText
							href={`https://twitter.com/${author.twitter}`}
							target="_blank"
							rel="noreferrer noopener nofollow"
						>
							@{author.twitter}
						</AuthorLinkText>
					</Column>
					<Column className="third">
						<AuthorLinkHeading>GitHub</AuthorLinkHeading>
						<AuthorLinkText
							href={`https://github.com/${author.github}`}
							target="_blank"
							rel="noreferrer noopener nofollow"
						>
							github.com/{author.github}
						</AuthorLinkText>
					</Column>
					<Column className="third">
						<AuthorLinkHeading>LinkedIn</AuthorLinkHeading>
						<AuthorLinkText
							href={`https://www.linkedin.com/in/${author.linkedin}/`}
							target="_blank"
							rel="noreferrer noopener nofollow"
						>
							linked/in/{author.linkedin}
						</AuthorLinkText>
					</Column>
				</Columns>
			</AuthorLinks>
		</AuthorContainer>
	);
}
