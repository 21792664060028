import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import { useLocation } from '@reach/router';
import { GatsbyImage } from 'gatsby-plugin-image';
import dayjs from 'dayjs';
import { Constrain, GeneralBody } from '../Containers';
import YoutubeEmbed from '../YoutubeEmbed';
import Toc from '../Doc/Toc';
import MDXProvider from '../Typography/MDXProvider';
import { MDXRenderer } from 'gatsby-plugin-mdx';
import {
	BlogTitle,
	BlogContainer,
	TocContainer,
	Subtitle,
	MainDescription,
	BlogFooter,
	FooterDivider,
	FeaturedImage,
	ImageCredit,
} from './styled';
import Button from '../Button';
import Card from '../Card';
import authors from '../../data/authors.json';
import Plugs from './Plugs';
import Author from './Author';

export type BlogProps = {
	mdx: GatsbyTypes.SingleBlogQuery['mdx'];
	recent: GatsbyTypes.SingleBlogQuery['recent'];
	slug: string;
};
export default function Blog(props: BlogProps) {
	const { mdx, recent } = props;
	const { site } = useStaticQuery<GatsbyTypes.BlogPostMetaDataQuery>(graphql`
		query BlogPostMetaData {
			site {
				siteMetadata {
					siteUrl
					og {
						twitterCreator
					}
				}
			}
		}
	`);
	// Use the location hook to get current page URL
	const location = useLocation();
	const currentUrl = `${site!.siteMetadata!.siteUrl}${location.pathname}`;

	const subtitle: string[] = [
		`Published on ${dayjs.utc(mdx!.frontmatter!.date).local().format('LL')}`,
		`${mdx!.timeToRead}m read`,
	];

	const author = authors.find(a => a.authorId === mdx?.frontmatter?.author);

	return (
		<Constrain>
			<GeneralBody className="blog">
				<BlogContainer>
					<BlogTitle as="h1">{mdx?.frontmatter?.heading}</BlogTitle>
					<Subtitle>{subtitle.join(' • ')}</Subtitle>
					<MainDescription>{mdx?.frontmatter?.description}</MainDescription>
					{mdx?.frontmatter?.youtubeVideoId ? (
						<FeaturedImage>
							<YoutubeEmbed videoId={mdx.frontmatter.youtubeVideoId} />
						</FeaturedImage>
					) : mdx?.frontmatter?.featuredImage?.childImageSharp
							?.gatsbyImageData ? (
						<FeaturedImage>
							<GatsbyImage
								image={
									mdx.frontmatter.featuredImage.childImageSharp.gatsbyImageData
								}
								alt={mdx.frontmatter.title}
							/>
							{mdx.frontmatter.imageBy && mdx.frontmatter.imageByLink ? (
								<ImageCredit>
									Image by{' '}
									<a
										href={mdx.frontmatter.imageByLink}
										target="_blank"
										rel="nofollow noopener noreferrer"
									>
										{mdx.frontmatter.imageBy}
									</a>
									{mdx.frontmatter.imageFrom &&
									mdx.frontmatter.imageFromLink ? (
										<>
											{' '}
											from{' '}
											<a
												href={mdx.frontmatter.imageFromLink}
												target="_blank"
												rel="nofollow noopener noreferrer"
											>
												{mdx.frontmatter.imageFrom}
											</a>
										</>
									) : null}
									.
								</ImageCredit>
							) : null}
						</FeaturedImage>
					) : null}
					<TocContainer>
						<Toc items={(mdx?.tableOfContents as any)?.items ?? undefined} />
					</TocContainer>
					<MDXProvider>
						<MDXRenderer>{mdx!.body}</MDXRenderer>
					</MDXProvider>
					<BlogFooter>
						<FooterDivider>
							Last updated{' '}
							{dayjs
								.utc(mdx!.frontmatter!.lastUpdated ?? mdx!.frontmatter!.date)
								.local()
								.format('LL')}
						</FooterDivider>
						<Button.Group align="flex-end">
							<Button
								as="link"
								type="ghost"
								target="_blank"
								rel="nofollow noreferrer noopener"
								href={`https://twitter.com/search?q=${encodeURIComponent(
									currentUrl
								)}`}
							>
								DISCUSS ON TWITTER
							</Button>
							<Button
								as="button"
								type="primary"
								onClick={() => {
									if (
										typeof window !== 'undefined' &&
										typeof navigator !== 'undefined' &&
										navigator.share
									) {
										navigator
											.share({
												title: 'wpeform.io',
												text: mdx!.frontmatter!.heading,
												url: currentUrl,
											})
											.catch(e => {
												console.log(e);
											});
									} else if (typeof window !== 'undefined') {
										window.open(
											`https://twitter.com/share?text=${encodeURIComponent(
												mdx!.frontmatter!.description!
											)}&url=${encodeURIComponent(
												currentUrl
											)}&via=swashata&hashtags=wpeformblog`,
											'_blank'
										);
									}
								}}
							>
								SHARE ARTICLE
							</Button>
						</Button.Group>
						{mdx?.frontmatter?.tags?.length ? (
							<Button.Group align="flex-end">
								{mdx.frontmatter.tags.map(tag => (
									<Button
										key={tag}
										as="link"
										type="regular"
										size="small"
										href={`/blog/category/${tag!.trim().toLowerCase()}/`}
									>
										{tag!.trim()}
									</Button>
								))}
							</Button.Group>
						) : null}
						{author ? <Author author={author} /> : null}
						{recent.nodes.length ? (
							<>
								<FooterDivider largeGutter>Recently from Blog</FooterDivider>
								<Card.Grid>
									{recent.nodes.map(node => (
										<Card
											key={node.id}
											layout="in-blog-footer"
											to={`/${node.slug}`}
											image={
												node.frontmatter?.featuredImage?.childImageSharp ? (
													<>
														<GatsbyImage
															image={
																node.frontmatter.featuredImage.childImageSharp
																	.gatsbyImageData
															}
															alt={node.frontmatter.heading ?? ''}
														/>
													</>
												) : null
											}
										>
											<Card.Title>{node.frontmatter?.heading}</Card.Title>
											<Card.Description>{node.excerpt}</Card.Description>
											<Card.FakeButton>Continue Reading…</Card.FakeButton>
										</Card>
									))}
								</Card.Grid>
							</>
						) : null}
						<Plugs />
					</BlogFooter>
				</BlogContainer>
			</GeneralBody>
		</Constrain>
	);
}
