import React from 'react';
import { graphql, PageProps } from 'gatsby';
import Seo from '../components/Seo';
import Blog from '../components/Blog';
import FooterCredit from '../components/FooterCredit';

type PageContextType = {
	slug: string;
};

export default function Template(
	props: PageProps<GatsbyTypes.SingleBlogQuery, PageContextType>
) {
	const {
		data: { mdx, recent },
		pageContext: { slug },
	} = props;

	return (
		<>
			<Seo
				description={mdx?.frontmatter?.description}
				title={mdx?.frontmatter?.title}
				keywords={mdx?.frontmatter?.keywords}
				featuredImage={
					mdx?.frontmatter?.featuredImage?.childImageSharp?.gatsbyImageData
				}
			/>
			<Blog mdx={mdx} recent={recent} slug={slug} />
			<FooterCredit />
		</>
	);
}

export const query = graphql`
	query SingleBlog($slug: String!) {
		mdx(fields: { slug: { eq: $slug } }) {
			frontmatter {
				title
				heading
				description
				tags
				date
				lastUpdated
				author
				keywords
				featuredImage {
					childImageSharp {
						gatsbyImageData(layout: CONSTRAINED, width: 1200)
					}
				}
				imageBy
				imageByLink
				imageFrom
				imageFromLink
				youtubeVideoId
			}
			body
			tableOfContents
			timeToRead
		}
		recent: allMdx(
			filter: {
				fileAbsolutePath: { glob: "**/site-data/blog/**/*.mdx" }
				frontmatter: { published: { eq: true } }
				fields: { slug: { ne: $slug } }
			}
			sort: { fields: frontmatter___date, order: DESC }
			limit: 4
		) {
			nodes {
				id
				slug
				frontmatter {
					date
					heading
					featuredImage {
						childImageSharp {
							gatsbyImageData(
								layout: CONSTRAINED
								width: 850
								placeholder: TRACED_SVG
								aspectRatio: 2.0
							)
						}
					}
				}
				excerpt(pruneLength: 90, truncate: false)
			}
		}
	}
`;
